import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Image from '../components/Image';

const SponsorsPage = () => (
  <Layout>
    <SEO title="Sponsors" />

    <div className="hero">
      <Image className="hero__image left-mobile" fileName="resources-hero.png" />

      <div className="hero__overlay" />

      <div className="hero__content">
        <div className="wrapper">
          <h1>Coach. Podcaster. COACHCASTER.</h1>

          <p className="hero__blurb">Health coach turned podcaster, Chase interviews industry leaders in fitness nutrition and mindset. Messages to help you live a life EVER FORWARD.</p>

          <h2>Chase Chewning, MS, ACE CHC</h2>

          <div className="actions">
            <a
              href="/https://podcasts.apple.com/gb/podcast/ever-forward-radio/id1195967486"
              className="button button--blue"
              target="_blank"
              rel="noreferrer noopener"
            >
              Listen on Apple Podcasts
            </a>

            <p>or</p>

            <Link to="/contact" className="button button--white">Contact Me</Link>
          </div>
        </div>
      </div>
    </div>

    <section>
      <div className="container container--slim">
        <h2 className="text-center section-header">Sponsors</h2>

        <div className="grid grid--align-center">
          <div className="grid__col--xs-12 grid__col--sm-6">
            <a href="https://www.bareperformancenutrition.com/" target="_blank" rel="noreferrer noopener">
              <Image fileName="bpn.png" />
            </a>
          </div>

          <div className="grid__col--xs-12 grid__col--sm-6">
            <div className="sponsor-text">
              <h3>
                <a href="https://www.bareperformancenutrition.com/" target="_blank" rel="noreferrer noopener">Bare Performance Nutrition</a>
              </h3>

              <p>
                {'Veteran-owned and made in the USA, BPN is a performance, recovery, and healthy lifestyle supplements company - '}
                <em>code CHASE for 10% off</em>
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid--align-center">
          <div className="grid__col--xs-12 grid__col--sm-6">
            <a href="https://www.foursigmatic.com/everforward" target="_blank" rel="noreferrer noopener">
              <Image fileName="four-sigmatic.png" />
            </a>
          </div>

          <div className="grid__col--xs-12 grid__col--sm-6">
            <div className="sponsor-text">
              <h3>
                <a href="https://www.foursigmatic.com/everforward" target="_blank" rel="noreferrer noopener">Four Sigmatic</a>
              </h3>

              <p>
                {'Organic superfood, medicinal mushroom, and adaptogen wellness products to improve your daily habits, skincare, coffees, elixirs, hot cacaos and more - '}
                <em>code EVERFORWARD for 15% off</em>
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid--align-center">
          <div className="grid__col--xs-12 grid__col--sm-6">
            <a href="http://barkleyeyewear.com?aff=14" target="_blank" rel="noreferrer noopener">
              <Image fileName="barkley-eyewear.png" />
            </a>
          </div>

          <div className="grid__col--xs-12 grid__col--sm-6">
            <div className="sponsor-text">
              <h3>
                <a href="http://barkleyeyewear.com?aff=14" target="_blank" rel="noreferrer noopener">Barkley Eyewear</a>
              </h3>

              <p>
                {'Function and fashion meet wellness! Barkley makes the world’s first all-in-one daytime and evening blue light blocking eyewear - '}
                <em>code EVERFORWARD for 10% off</em>
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid--align-center">
          <div className="grid__col--xs-12 grid__col--sm-6">
            <a href="http://bit.ly/2RmXcja" target="_blank" rel="noreferrer noopener">
              <Image fileName="cured.png" />
            </a>
          </div>

          <div className="grid__col--xs-12 grid__col--sm-6">
            <div className="sponsor-text">
              <h3>
                <a href="http://bit.ly/2RmXcja" target="_blank" rel="noreferrer noopener">Cured Nutrition</a>
              </h3>

              <p>
                {'A Colorado community and company rooted in nature, Cured offers safe and sustainably sourced CBD wellness supplements - '}
                <em>code EVERFORWARD for 15% off</em>
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid--align-center">
          <div className="grid__col--xs-12 grid__col--sm-6">
            <a href="https://strongcoffeecompany.com/?ref=idvqn2vvx5y" target="_blank" rel="noreferrer noopener">
              <Image fileName="strong-coffee.png" />
            </a>
          </div>

          <div className="grid__col--xs-12 grid__col--sm-6">
            <div className="sponsor-text">
              <h3>
                <a href="https://strongcoffeecompany.com/?ref=idvqn2vvx5y" target="_blank" rel="noreferrer noopener">Strong Coffee Company</a>
              </h3>

              <p>
                {'Real coffee that promotes strong bodies and strong minds, Strong Coffee Co. makes instant coffee packed with MCTs, L-theanine, and collagen - '}
                <em>code CHASE for 15% off</em>
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid--align-center">
          <div className="grid__col--xs-12 grid__col--sm-6">
            <a href="http://www.audibletrial.com/everforward" target="_blank" rel="noreferrer noopener">
              <Image fileName="audible.png" />
            </a>
          </div>

          <div className="grid__col--xs-12 grid__col--sm-6">
            <div className="sponsor-text">
              <h3>
                <a href="http://www.audibletrial.com/everforward" target="_blank" rel="noreferrer noopener">Audible</a>
              </h3>

              <p>
                {'Get your first 30 days for free, which works out to your first book, from my favorite audio book platform - '}
                <a href="http://www.audibletrial.com/everforward" target="_blank" rel="noreferrer noopener">audibletrial.com/everforward</a>
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid--align-center">
          <div className="grid__col--xs-12 grid__col--sm-6">
            <a href="http://www.paleovalley.com/everforward" target="_blank" rel="noreferrer noopener">
              <Image fileName="paleovalley.png" />
            </a>
          </div>

          <div className="grid__col--xs-12 grid__col--sm-6">
            <div className="sponsor-text">
              <h3>
                <a href="http://www.paleovalley.com/everforward" target="_blank" rel="noreferrer noopener">Paleovalley</a>
              </h3>

              <p>
                {/* eslint-disable-next-line max-len */}
                I am seriously addicted to their 100% grass fed beef sticks - my favorite snack on the go! With so many flavors made from organic spices I am able to increase my daily protein intake from whole foods with these gut-friendly beef sticks. They also have an incredible lineup of non-GMO and gluten free supplements like bone broth protein, turmeric, apple cider vinegar complex, and more to help improve my daily wellness.
                <strong> Save 15% with code EVERFORWARD!</strong>
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid--align-center">
          <div className="grid__col--xs-12 grid__col--sm-6">
            <a href="http://comradsocks.com/everforward" target="_blank" rel="noreferrer noopener">
              <Image fileName="comrad.png" />
            </a>
          </div>

          <div className="grid__col--xs-12 grid__col--sm-6">
            <div className="sponsor-text">
              <h3>
                <a href="http://comradsocks.com/everforward" target="_blank" rel="noreferrer noopener">Comrad Socks</a>
              </h3>

              <p>
                {/* eslint-disable-next-line max-len */}
                Socks with benefits. I wear Comrad Socks every time I am traveling, on a road trip, when I am stuck behind my desk all day, or on any occasion where I may find myself more sedentary than usual as they help rejuvenate my entire essence, giving me improved vitality and energy. Compression therapy works by squeezing the veins in your legs and feet, making them narrower, which helps blood flow up the legs toward the heart. Better circulation keeps fresh, oxygenated blood flowing to energize muscles, reduce soreness, and prevent clots and circulatory conditions.
                <strong> Save 20% with code EVERFORWARD!</strong>
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid--align-center">
          <div className="grid__col--xs-12 grid__col--sm-6">
            <a href="http://www.calderalab.com/everforward" target="_blank" rel="noreferrer noopener">
              <Image fileName="caldera.png" />
            </a>
          </div>

          <div className="grid__col--xs-12 grid__col--sm-6">
            <div className="sponsor-text">
              <h3>
                <a href="http://www.calderalab.com/everforward" target="_blank" rel="noreferrer noopener">Caldera + Lab</a>
              </h3>

              <p>
                {/* eslint-disable-next-line max-len */}
                The Good serum has been one of my absolute favorite new products in my skin care routine. As an Army veteran, someone who trains hard most days of the week, and busy entrepreneur I rely on things that are simple yet effective. Most importantly - products that do what they say and don&apos;t take up too much of my time. Since using The Good I have not only saved time with my skin care routine but experienced noticeably rejuvenated and fresher looking skin.
                <strong> Save 20% with code EVERFORWARD!</strong>
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid--align-center">
          <div className="grid__col--xs-12 grid__col--sm-6">
            <a href="http://www.beekeepersnaturals.com/everforward" target="_blank" rel="noreferrer noopener">
              <Image fileName="beekeepers.png" />
            </a>
          </div>

          <div className="grid__col--xs-12 grid__col--sm-6">
            <div className="sponsor-text">
              <h3>
                <a href="http://www.beekeepersnaturals.com/everforward" target="_blank" rel="noreferrer noopener">Beekeeper&apos;s Naturals</a>
              </h3>

              <p>
                {/* eslint-disable-next-line max-len */}
                Seriously my new medicine cabinet! BKN offers wellness products from one of nature&apos;s most ancient sources - bees. Their propolis throat spray is a daily essential for me and something I use before every podcast interview as well as double down on when I am traveling for its immense immune boosting benefits. Also, their B-LXR is an incredible and all-natural nootropic that improves brain health and increases cognition as well as their nutrient-dense superfood honey, B. Powered.
                <strong> Save 15% with code EVERFORWARD!</strong>
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid--align-center">
          <div className="grid__col--xs-12 grid__col--sm-6">
            <a href="http://www.whoop.com/" target="_blank" rel="noreferrer noopener">
              <Image fileName="whoop.png" />
            </a>
          </div>

          <div className="grid__col--xs-12 grid__col--sm-6">
            <div className="sponsor-text">
              <h3>
                <a href="http://www.whoop.com/" target="_blank" rel="noreferrer noopener">WHOOP</a>
              </h3>

              {/* eslint-disable-next-line max-len */}
              <p>Not all wearables are alike! The one physical activity tracker I trust and rely on to measure my training, recovery, and sleep is WHOOP. You can get your activity tracker band for free and save 15% off of your new membership with code CHASE - and make sure to join the private Ever Forward team inside the app!</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default SponsorsPage;
